
/**** Dynamic icons - worksurface-dynamic icons ****/
.vismaicon-dynamic {
    &.vismaicon-absence {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_absence.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_absence.svg');
        }

    }

    &.vismaicon-absence-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_absence_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_absence_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_absence_1.svg'); 
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_absence_1.svg');
        }
    }

    &.vismaicon-absence-2 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_absence_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_absence_2.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_absence_2.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_absence_2.svg');
        }
    }

    &.vismaicon-add-to-checklist {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_to_checklist.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_to_checklist.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_add_to_checklist.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_add_to_checklist.svg');
        }
    }

    &.vismaicon-adjust-hours {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_adjust_hours.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_adjust_hours.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_adjust_hours.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_adjust_hours.svg');
        }
    }

    &.vismaicon-airplane {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_airplane.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_airplane.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_airplane.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_airplane.svg');
        }
    }

    &.vismaicon-attendance {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_attendance.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_attendance.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_attendance.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_attendance.svg');
        }
    }

    &.vismaicon-birth {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_birth.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_birth.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_birth.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_birth.svg');
        }
    }

    &.vismaicon-birthday {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_birthday.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_birthday.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_birthday.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_birthday.svg');
        }
    }

    &.vismaicon-bonus-gift {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bonus_gift.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bonus_gift.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_bonus_gift.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_bonus_gift.svg');
        }
    }

    &.vismaicon-calculator {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calculator.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calculator.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_calculator.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_calculator.svg');
        }
    }

    &.vismaicon-calendar-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_calendar_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_calendar_1.svg');
        }
    }

    &.vismaicon-cancel-order {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_cancel_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_cancel_order.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_cancel_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_cancel_order.svg');
        }
    }

    &.vismaicon-car {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_car.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_car.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_car.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_car.svg');
        }
    }

    &.vismaicon-shipping {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_shipping.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_shipping.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_shipping.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_shipping.svg');
        }
    }

    &.vismaicon-clipboard {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_clipboard.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_clipboard.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_clipboard.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_clipboard.svg');
        }
    }

    &.vismaicon-create-order {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_create_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_create_order.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_create_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_create_order.svg');
        }
    }

    &.vismaicon-comment-order {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_order.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_order.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_order.svg');
        }
    }

    &.vismaicon-construction {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_construction.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_construction.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_construction.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_construction.svg');
        }
    }

    &.vismaicon-contacts {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_contacts.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_contacts.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_contacts.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_contacts.svg');
        }
    }

    &.vismaicon-database {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_database.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_database.svg');
        }

        &.vismaicon-sm:before { 
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_database.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_database.svg');
        }
    }

    &.vismaicon-delivery-notification {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_notification.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_notification.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delivery_notification.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delivery_notification.svg');
        }
    }

    &.vismaicon-delivery-reception {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reception.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reception.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delivery_reception.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delivery_reception.svg');
        }
    }

    &.vismaicon-delivery-reception-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reception_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reception_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delivery_reception_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delivery_reception_1.svg');
        }
    }

    &.vismaicon-distribution {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_distribution.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_distribution.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_distribution.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_distribution.svg');
        }
    }

    &.vismaicon-documents-area {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_documents_area.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_documents_area.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_documents_area.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_documents_area.svg');
        }
    }

    &.vismaicon-edit-file {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_edit_file.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_edit_file.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_edit_file.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_edit_file.svg');
        }
    }

    &.vismaicon-environment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_environment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_environment.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_environment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_environment.svg');
        }
    }

    &.vismaicon-expense {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_expense.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_expense.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_expense.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_expense.svg');
        }
    }

    &.vismaicon-expense-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_expense_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_expense_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_expense_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_expense_1.svg');
        }
    }

    &.vismaicon-favorite-user {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_favorite_user.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_favorite_user.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_favorite_user.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_favorite_user.svg');
        }
    }

    &.vismaicon-fireworks {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_fireworks.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_fireworks.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_fireworks.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_fireworks.svg');
        }
    }

    &.vismaicon-folder {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_folder.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_folder.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_folder.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_folder.svg');
        }
    }

    &.vismaicon-future {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_future.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_future.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_future.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_future.svg');
        }
    }

    &.vismaicon-heart {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_heart.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_heart.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_heart.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_heart.svg');
        }
    }

    &.vismaicon-history {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_history.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_history.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_history.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_history.svg');
        }
    }

    &.vismaicon-holiday {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_holiday.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_holiday.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_holiday.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_holiday.svg');
        }
    }

    &.vismaicon-import-company {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_import_company.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_import_company.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_import_company.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_import_company.svg');
        }
    }

    &.vismaicon-import-user {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_import_user.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_import_user.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_import_user.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_import_user.svg');
        }
    }

    &.vismaicon-inclusive {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_inclusive.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_inclusive.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_inclusive.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_inclusive.svg');
        }
    }

    &.vismaicon-incoming {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_incoming.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_incoming.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_incoming.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_incoming.svg');
        }
    }

    &.vismaicon-key {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_key.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_key.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_key.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_key.svg');
        }
    }

    &.vismaicon-key-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_key_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_key_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_key_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_key_1.svg');
        }
    }

    &.vismaicon-payroll-calculations,
    &.vismaicon-invoice {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_invoice.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_invoice.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_invoice.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_invoice.svg');
        }
    }

    &.vismaicon-lightbulb {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_light_bulb.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_light_bulb.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_light_bulb.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_light_bulb.svg');
        }
    }

    &.vismaicon-lodging {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_lodging.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_lodging.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_lodging.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_lodging.svg');
        }
    }

    &.vismaicon-mail-notification {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_mail_notification.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_mail_notification.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_mail_notification.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_mail_notification.svg');
        }
    }

    &.vismaicon-managers-time-registration { 
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_managers_time_registration.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_managers_time_registration.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_managers_time_registration.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_managers_time_registration.svg');
        }
    }

    &.vismaicon-time {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_time.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_time.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_time.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_time.svg');
        }
    }

    &.vismaicon-chat,
    &.vismaicon-message-bubble {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_message_bubble.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_message_bubble.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_message_bubble.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_message_bubble.svg');
        }
    }

    &.vismaicon-multiple-selection {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_multiple_selection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_multiple_selection.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_multiple_selection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_multiple_selection.svg');
        }
    }

    &.vismaicon-no-edit {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_no_edit.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_no_edit.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_no_edit.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_no_edit.svg');
        }
    }

    &.vismaicon-order-imported {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_order_imported.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_order_imported.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_order_imported.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_order_imported.svg');
        }
    }

    &.vismaicon-overtime {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_overtime.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_overtime.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_overtime.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_overtime.svg');
        }
    }

    &.vismaicon-paperplane {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_paperplane.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_paperplane.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_paperplane.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_paperplane.svg');
        }
    }

    &.vismaicon-parental-leave {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_parental_leave.svg'); 
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_parental_leave.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_parental_leave.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_parental_leave.svg');
        }
    }

    &.vismaicon-payment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_payment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_payment.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_payment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_payment.svg');
        }
    }

    &.vismaicon-paste,
    &.vismaicon-clipboard-paper {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_clipboard_paper.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_clipboard_paper.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_clipboard_paper.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_clipboard_paper.svg');
        }
    }

    &.vismaicon-paste-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_paste_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_paste_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_paste_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_paste_1.svg');
        }
    }

    &.vismaicon-reset {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_reset.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_reset.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_reset.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_reset.svg');
        }
    }

    &.vismaicon-ribbon-medal {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_ribbon_medal.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_ribbon_medal.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_ribbon_medal.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_ribbon_medal.svg');
        }
    }

    &.vismaicon-schedule {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_schedule.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_schedule.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_schedule.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_schedule.svg');
        }
    }

    &.vismaicon-sick-child {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sick_child.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sick_child.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sick_child.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sick_child.svg');
        }
    }

    &.vismaicon-sickness {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sickness.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sickness.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sickness.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sickness.svg');
        }
    }

    &.vismaicon-single-selection {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_single_selection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_single_selection.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_single_selection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_single_selection.svg');
        }
    }

    &.vismaicon-special-approved {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_special_approved.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_special_approved.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_special_approved.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_special_approved.svg');
        }
    }

    &.vismaicon-special-day {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_special_day.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_special_day.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_special_day.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_special_day.svg');
        }
    }

    &.vismaicon-special-day-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_special_day_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_special_day_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_special_day_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_special_day_1.svg');
        }
    }

    &.vismaicon-storage-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_storage_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_storage_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_storage_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_storage_1.svg');
        }
    }

    &.vismaicon-suitcase {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_suitcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_suitcase.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_suitcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_suitcase.svg');
        }
    }

    &.vismaicon-summary {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_summary.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_summary.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_summary.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_summary.svg');
        }
    }

    &.vismaicon-tie {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tie.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tie.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_tie.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_tie.svg');
        }
    }

    &.vismaicon-timesheet {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_timesheet.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_timesheet.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_timesheet.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_timesheet.svg');
        }
    }

    &.vismaicon-time-report {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_time_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_time_report.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_time_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_time_report.svg');
        }
    }

    &.vismaicon-timesheet {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_timesheet.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_timesheet.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_timesheet.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_timesheet.svg');
        }
    }

    &.vismaicon-translate {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_translate.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_translate.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_translate.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_translate.svg');
        }
    }

    &.vismaicon-user-connection {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_connection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_connection.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_connection.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_connection.svg');
        }
    }

    &.vismaicon-user-list {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_list.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_list.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_list.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_list.svg');
        }
    }

    &.vismaicon-vacation {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_vacation.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_vacation.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_vacation.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_vacation.svg');
        }
    }

    &.vismaicon-vacation-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_vacation_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_vacation_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_vacation_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_vacation_1.svg');
        }
    }

    &.vismaicon-vacation-suitcase {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_vacation_suitcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_vacation_suitcase.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_vacation_suitcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_vacation_suitcase.svg');
        }
    }

    &.vismaicon-video-1 {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_video_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_video_1.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_video_1.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_video_1.svg');
        }
    }

    &.vismaicon-working-day {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_working_day.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_working_day.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_working_day.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_working_day.svg');
        }
    }

    &.vismaicon-working-time {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_working_time.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_working_time.svg');
        }

        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_working_time.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_working_time.svg');
        }
    }

    &.vismaicon-print {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_print.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_print.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_print.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_print.svg');
        }
    }

    &.vismaicon-edit {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_edit.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_edit.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_edit.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_edit.svg');
        }
    }

    &.vismaicon-save {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_save.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_save.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_save.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_save.svg');
        }
    }

    &.vismaicon-delete {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delete.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delete.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delete.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_delete.svg');
        }
    }

    &.vismaicon-attach {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_attach.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_attach.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_attach.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_attach.svg');
        }
    }

    &.vismaicon-briefcase {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_briefcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_briefcase.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_briefcase.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_briefcase.svg');
        }
    }

    &.vismaicon-new {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_new.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_new.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_new.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_new.svg');
        }
    }

    &.vismaicon-comment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment.svg');
        }
    }

    &.vismaicon-unread-comment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_unread_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_unread_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_unread_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_unread_comment.svg');
        }
    }

    &.vismaicon-add-comment {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_add_comment.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_add_comment.svg');
        }
    }

    &.vismaicon-zoom-in {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_zoom_in.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_zoom_in.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_zoom_in.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_zoom_in.svg');
        }
    }

    &.vismaicon-zoom-out {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_zoom_out.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_zoom_out.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_zoom_out.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_zoom_out.svg');
        }
    }

    &.vismaicon-undo {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_undo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_undo.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_undo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_undo.svg');
        }
    }

    &.vismaicon-redo {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_redo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_redo.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_redo.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_redo.svg');
        }
    }

    &.vismaicon-flag {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_flag.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_flag.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_flag.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_flag.svg');
        }
    }

    &.vismaicon-cut {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_cut.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_cut.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_cut.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_cut.svg');
        }
    }

    &.vismaicon-copy {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_copy.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_copy.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_copy.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_copy.svg');
        }
    }

    &.vismaicon-paste {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_paste.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_paste.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_paste.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_paste.svg');
        }
    }

    &.vismaicon-report {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_report.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_report.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_report.svg');
        }
    }

    &.vismaicon-home {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_home.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_home.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_home.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_home.svg');
        }
    }

    &.vismaicon-refresh {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_refresh.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_refresh.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_refresh.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_refresh.svg');
        }
    }

    &.vismaicon-employee,
    &.vismaicon-user {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user.svg');
        }
    }

    &.vismaicon-users {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_users.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_users.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_users.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_users.svg');
        }
    }

    &.vismaicon-time {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_time.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_time.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_time.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_time.svg');
        }
    }

    &.vismaicon-search {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_search.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_search.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_search.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_search.svg');
        }
    }

    &.vismaicon-note {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_note.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_note.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_note.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_note.svg');
        }
    }

    &.vismaicon-graph,
    &.vismaicon-graphs {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_graph.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_graph.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_graph.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_graph.svg');
        }
    }

    &.vismaicon-email {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_email.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_email.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_email.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_email.svg');
        }
    }

    &.vismaicon-phone {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_phone.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_phone.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_phone.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_phone.svg');
        }
    }

    &.vismaicon-locked {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_locked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_locked.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_locked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_locked.svg');
        }
    }

    &.vismaicon-unlocked {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_unlocked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_unlocked.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_unlocked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_unlocked.svg');
        }
    }

    &.vismaicon-price,
    &.vismaicon-money {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_money.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_money.svg');
    }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_money.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_money.svg');
    }
    }

    &.vismaicon-web {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_web.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_web.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_web.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_web.svg');
        }
    }

    &.vismaicon-open {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_open.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_open.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_open.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_open.svg');
        }
    }

    &.vismaicon-upload {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_upload.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_upload.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_upload.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_upload.svg');
        }
    }

    &.vismaicon-download {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_download.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_download.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_download.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_download.svg');
        }
    }

    &.vismaicon-preview {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_preview.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_preview.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_preview.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_preview.svg');
        }
    }

    &.vismaicon-favourites,
    &.vismaicon-favourite {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_favourite.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_favourite.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_favourite.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_favourite.svg');
        }
    }

    &.vismaicon-like {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_like.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_like.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_like.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_like.svg');
        }
    }

    &.vismaicon-dislike {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_dislike.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_dislike.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_dislike.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_dislike.svg');
        }
    }

    &.vismaicon-filter {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_filter.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_filter.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_filter.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_filter.svg');
        }
    }

    &.vismaicon-export {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_export.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_export.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_export.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_export.svg');
        }
    }

    &.vismaicon-changelog {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_changelog.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_changelog.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_changelog.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_changelog.svg');
        }
    }

    &.vismaicon-replace {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_replace.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_replace.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_replace.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_replace.svg');
        }
    }

    &.vismaicon-drag {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_drag.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_drag.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_drag.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_drag.svg');
        }
    }

    &.vismaicon-image-document {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_image_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_image_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_image_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_image_document.svg');
        }
    }

    &.vismaicon-xml-document {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_xml_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_xml_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_xml_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_xml_document.svg');
        }
    }

    &.vismaicon-add-user {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_user.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_user.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_add_user.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_add_user.svg');
        }
    }

    &.vismaicon-alert {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_alert.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_alert.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_alert.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_alert.svg');
        }
    }

    &.vismaicon-announcement {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_announcement.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_announcement.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_announcement.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_announcement.svg');
        }
    }

    &.vismaicon-archive-document {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_archive_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_archive_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_archive_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_archive_document.svg');
        }
    }

    &.vismaicon-contract {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_contract.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_contract.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_contract.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_contract.svg');
        }
    }

    &.vismaicon-database {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_database.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_database.svg');
        }
        &.vismaicon-sm:before { 
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_database.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_database.svg');
        }
    }

    &.vismaicon-detach {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_detach.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_detach.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_detach.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_detach.svg');
        }
    }

    &.vismaicon-document-info {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_document_info.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_document_info.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_document_info.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_document_info.svg');
        }
    }

    &.vismaicon-education {
        &:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_education.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_education.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_education.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_education.svg');
        }
    }

    &.vismaicon-enter-full-screen {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_enter_full_screen.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_enter_full_screen.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_enter_full_screen.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_enter_full_screen.svg');
        }
    }

    &.vismaicon-exit-full-screen {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_exit_full_screen.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_exit_full_screen.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_exit_full_screen.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_exit_full_screen.svg');
        }
    }

    &.vismaicon-evaluate {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_evaluate.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_evaluate.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_evaluate.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_evaluate.svg');
        }
    }

    &.vismaicon-exclude {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_exclude.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_exclude.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_exclude.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_exclude.svg');
        }
    }

    &.vismaicon-folder-building {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_folder_building.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_folder_building.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_folder_building.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_folder_building.svg');
        }
    }

    &.vismaicon-hourglass {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_hourglass.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_hourglass.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_hourglass.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_hourglass.svg');
        }
    }

    &.vismaicon-link {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_link.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_link.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_link.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_link.svg');
        }
    }

    &.vismaicon-location {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_location.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_location.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_location.svg');
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_location.svg');
        }
    }

    &.vismaicon-logon-failed {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_logon_failed.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_logon_failed.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_logon_failed.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_logon_failed.svg');
        }
    }

    &.vismaicon-lunch {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_lunch.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_lunch.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_lunch.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_lunch.svg');
        }
    }

    &.vismaicon-note-blank {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_note_blank.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_note_blank.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_note_blank.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_note_blank.svg');
        }
    }

    &.vismaicon-note-filled {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_note_filled.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_note_filled.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_note_filled.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_note_filled.svg');
        }
    }

    &.vismaicon-office-building {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_office_building.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_office_building.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_office_building.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_office_building.svg');
        }
    }

    &.vismaicon-panel {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_panel.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_panel.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_panel.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_panel.svg');
        }
    }

    &.vismaicon-price {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_price.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_price.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_price.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_price.svg');
        }
    }

    &.vismaicon-rotate-ccw {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_rotate_ccw.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_rotate_ccw.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_rotate_ccw.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_rotate_ccw.svg');
        }
    }
        
    &.vismaicon-rotate-cw {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_rotate_cw.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_rotate_cw.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_rotate_cw.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_rotate_cw.svg');
        }
    }

    &.vismaicon-key,
    &.vismaicon-secret-document {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_key.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_key.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_key.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_key.svg');
        }
    }

    &.vismaicon-send {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_send.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_send.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_send.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_send.svg');
        }
    }

    &.vismaicon-sending-failed {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sending_failed.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sending_failed.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sending_failed.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sending_failed.svg');
        }
    }

    &.vismaicon-shared {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_shared_with_me.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_shared_with_me.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_shared_with_me.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_shared_with_me.svg');
        }
    }

    &.vismaicon-sound {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sound.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sound.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sound.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sound.svg');
        }
    }

    &.vismaicon-text-document {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_text_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_text_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_text_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_text_document.svg');
        }
    }

    &.vismaicon-undefined-document {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_undefined_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_undefined_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_undefined_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_undefined_document.svg');
        }
    }

    &.vismaicon-user-folder {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_folder.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_folder.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_folder.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_folder.svg');
        }
    }

    &.vismaicon-user-placeholder {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_placeholder.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_placeholder.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_placeholder.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_placeholder.svg');
        }
    }

    &.vismaicon-video {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_video.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_video.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_video.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_video.svg');
        }
    }

    &.vismaicon-xml {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_xml.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_xml.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_xml.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_xml.svg');
        }
    }

    &.vismaicon-filter-column {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_filter_column.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_filter_column.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_filter_column.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_filter_column.svg');
        }
    }

    &.vismaicon-agreement {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_agreement.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_agreement.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_agreement.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_agreement.svg');
        }
    }

    &.vismaicon-change-owner {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_change_owner.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_change_owner.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_change_owner.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_change_owner.svg');
        }
    }

    &.vismaicon-break,
    &.vismaicon-coffee-break {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_coffee_break.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_coffee_break.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_coffee_break.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_coffee_break.svg');
        }
    }

    &.vismaicon-comment-chat {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_chat.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_chat.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_chat.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_chat.svg');
        }
    }

    &.vismaicon-comment-community {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_community.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_community.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_community.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_community.svg');
        }
    }

    &.vismaicon-comment-web-reply {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_web_reply.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_comment_web_reply.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_web_reply.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_comment_web_reply.svg');
        }
    }

    &.vismaicon-jira {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_jira.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_jira.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_jira.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_jira.svg');
        }
    }

    &.vismaicon-list-view {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_list_view.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_list_view.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_list_view.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_list_view.svg');
        }
    }

    &.vismaicon-platform-cloud {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_platform_cloud.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_platform_cloud.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_platform_cloud.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_platform_cloud.svg');
        }
    }

    &.vismaicon-platform-mobile {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_platform_mobile.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_platform_mobile.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_platform_mobile.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_platform_mobile.svg');
        }
    }

    // &.vismaicon-sort-list {
    //     &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sort_list.svg');
    //         mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sort_list.svg');
    //     }
    //     &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sort_list.svg');
    //         mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_sort_list.svg');
    //     }
    // }

    &.vismaicon-storage {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_storage.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_storage.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_storage.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_storage.svg');
        }
    }

    &.vismaicon-tile-view {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tile_view.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tile_view.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_tile_view.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_tile_view.svg');
        }
    }

    &.vismaicon-reminder,
    &.vismaicon-alarm {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_alarm.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_alarm.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_alarm.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_alarm.svg');
        }
    }

    // &.vismaicon-calendar { 
    //     &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar.svg');
    //         mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar.svg');
    //     }
    //     &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_calendar.svg');
    //         mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_calendar.svg');
    //     }
    // }

    &.vismaicon-docs,
    &.vismaicon-document-production,
    &.vismaicon-document_production {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_document_production.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_document_production.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_document_production.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_document_production.svg');
        }
    }

    &.vismaicon-info {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_info.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_info.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_info.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_info.svg');
        }
    }

    &.vismaicon-library {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_library.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_library.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_library.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_library.svg');
        }
    }

    &.vismaicon-logout {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_logout.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_logout.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_logout.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_logout.svg');
        }
    }

    &.vismaicon-pin {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pin.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pin.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_pin.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_pin.svg');
        }
    }

    &.vismaicon-settings {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_settings.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_settings.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_settings.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_settings.svg');
        }
    }

    &.vismaicon-shopping-cart,
    &.vismaicon-shopping_cart {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_shopping_cart.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_shopping_cart.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_shopping_cart.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_shopping_cart.svg');
        }
    }

    &.vismaicon-tools {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tools.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tools.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_tools.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_tools.svg');
        }
    }

    &.vismaicon-user-settings,
    &.vismaicon-user_settings {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_settings.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_settings.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_settings.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_user_settings.svg');
        }
    }

    &.vismaicon-summarize {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_summarize.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_summarize.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_summarize.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_summarize.svg');
        }
    }

    &.vismaicon-add-favorite {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_to_favorite.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_add_to_favorite.svg');
        }
    }

    &.vismaicon-backspace {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_backspace.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_backspace.svg');
        }
    }

    &.vismaicon-breadcrumb-folder {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_breadcrumb_folder.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_breadcrumb_folder.svg');
        }
    }

    &.vismaicon-bus {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bus.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bus.svg');
        }
    }

    &.vismaicon-cake {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_cake.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_cake.svg');
        }
    }

    &.vismaicon-add-calendar {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar_add.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar_add.svg');
        }
    }

    &.vismaicon-edit-calendar {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar_edit.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_calendar_edit.svg');
        }
    }

    &.vismaicon-car-2 {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_car_2.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_car_2.svg');
        }
    }

    &.vismaicon-color {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_color.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_color.svg');
        }
    }

    &.vismaicon-datachart {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_datachart.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_datachart.svg');
        }
    }

    &.vismaicon-declined {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_declined.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_declined.svg');
        }
    }

    &.vismaicon-face-scan {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_face_scan.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_face_scan.svg');
        }
    }

    &.vismaicon-file-zoom {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_file_zoom.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_file_zoom.svg');
        }
    }

    &.vismaicon-font-bold {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_font_bold.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_font_bold.svg');
        }
    }

    &.vismaicon-font-underline {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_font_underline.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_font_underline.svg');
        }
    }

    &.vismaicon-help {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_help.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_help.svg');
        }
    }

    &.vismaicon-layer-group {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_layer_group.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_layer_group.svg');
        }
    }

    &.vismaicon-layers {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_layers.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_layers.svg');
        }
    }

    &.vismaicon-layout {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_layout.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_layout.svg');
        }
    }

    &.vismaicon-more {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_more.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_more.svg');
        }
    }

    &.vismaicon-motorcycle {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_motorcycle.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_motorcycle.svg');
        }
    }

    &.vismaicon-multiple-screens {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_multiple_screens.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_multiple_screens.svg');
        }
    }

    &.vismaicon-my-favorite {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_my_favorite.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_my_favorite.svg');
        }
    }

    &.vismaicon-news {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_news.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_news.svg');
        }
    }

    &.vismaicon-pdf {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pdf.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pdf.svg');
        }
    }

    &.vismaicon-preview-document {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_preview_document.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_preview_document.svg');
        }
    }

    &.vismaicon-qr-code {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_qr_code.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_qr_code.svg');
        }
    }

    &.vismaicon-reassign {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_reassign.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_reassign.svg');
        }
    }

    &.vismaicon-remove-favorite {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_remove_from_favorite.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_remove_from_favorite.svg');
        }
    }

    &.vismaicon-review {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_review.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_review.svg');
        }
    }

    &.vismaicon-settings-checked {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_settings_checked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_settings_checked.svg');
        }
    }

    &.vismaicon-settings-checked-2 {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_settings_checked_2.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_settings_checked_2.svg');
        }
    }

    &.vismaicon-substitute {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_substitute.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_substitute.svg');
        }
    }

    &.vismaicon-truck {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_truck.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_truck.svg');
        }
    }

    &.vismaicon-user-edit {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_edit.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_edit.svg');
        }
    }

    &.vismaicon-user-logged-out {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_logged_out.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_logged_out.svg');
        }
    }

    &.vismaicon-voucher {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_voucher.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_voucher.svg');
        }
    }

    &.vismaicon-workflow {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_workflow.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_workflow.svg');
        }
    }



    // 22.09.22
    &.vismaicon-plus {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_plus.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_plus.svg');
        }

        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_plus.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_plus.svg');
        }
    }

    &.vismaicon-minus {
        &:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_minus.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_minus.svg');
        }

        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_minus.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_minus.svg');
        } 
    }

    
    &.vismaicon-calendar,
    &.vismaicon-datepicker {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_datepicker.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_datepicker.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_datepicker.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_datepicker.svg');
        } 
    }

    &.vismaicon-autocomplete {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_autocomplete.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_autocomplete.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_autocomplete.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_autocomplete.svg');
        } 
    }

    &.vismaicon-multiselection {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_multiselection.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_multiselection.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_multiselection.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_multiselection.svg');
        } 
    }


    &.vismaicon-close {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_close.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_close.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_close.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/16_close.svg');
        } 
    }

    &.vismaicon-robot {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_robot.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_robot.svg');
        }
    } 

    &.vismaicon-accesibility {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_accesibility.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_accesibility.svg');
        }
    } 

    &.vismaicon-accesibility-locked {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_accesibility_locked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_accesibility_locked.svg');
        }
    } 

    &.vismaicon-file-locked {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_file_locked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_file_locked.svg');
        }
    } 

    &.vismaicon-growth {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_growth.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_growth.svg');
        }
    } 

    &.vismaicon-privacy {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_privacy_locked.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_privacy_locked.svg');
        }
    } 

    &.vismaicon-sort-list {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sort_list.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_sort_list.svg');
        }
    } 

    &.vismaicon-tag {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tag.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_tag.svg');
        }
    } 


    // 20.12.22
    &.vismaicon-approved {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_approved.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_approved.svg');
        }
    } 

    &.vismaicon-delivery-reports {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reports.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reports.svg');
        }
    } 

    &.vismaicon-domains {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_domains.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_domains.svg'); 
        }
    } 

    &.vismaicon-call-center {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_call_center.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_call_center.svg');
        }
    } 

    &.vismaicon-delivery-reports {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reports.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_delivery_reports.svg');
        }
    } 

    &.vismaicon-pagination {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pagination.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pagination.svg');
        }
    } 

    &.vismaicon-remarks {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_remarks.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_remarks.svg');
        }
    } 

    &.vismaicon-third-party-domain {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_third_party_domain.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_third_party_domain.svg');
        }
    } 

    &.vismaicon-user-percentage {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_percentage.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_percentage.svg');
        }
    } 

    &.vismaicon-user-reports {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_reports.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_user_reports.svg');
        }
    } 

    &.vismaicon-website-reports {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_website_reports.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_website_reports.svg');
        }
    } 

    &.vismaicon-website-settings {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_website_settings.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_website_settings.svg');
        }
    } 


    // 6.02.2023
    &.vismaicon-accommodation {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_accommodation.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_accommodation.svg');
        }
    } 

    &.vismaicon-bicycle {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bicycle.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bicycle.svg');
        }
    } 

    &.vismaicon-boat {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_boat.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_boat.svg');
        }
    } 

    &.vismaicon-breakfast {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_breakfast.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_breakfast.svg');
        }
    } 

    &.vismaicon-bridge {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bridge.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bridge.svg');
        }
    } 

    &.vismaicon-ferry {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_ferry.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_ferry.svg');
        }
    } 

    &.vismaicon-fuel-pump {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_fuel_pump.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_fuel_pump.svg');
        }
    } 

    &.vismaicon-bed-and-breakfast {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bed-and-breakfast.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_bed-and-breakfast.svg');
        }
    } 

    &.vismaicon-list {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_list.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_list.svg');
        }
    } 

    &.vismaicon-pedestrian {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pedestrian.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_pedestrian.svg');
        }
    } 

    &.vismaicon-parking {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_parking.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_parking.svg');
        }
    } 

    &.vismaicon-receipt {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_receipt.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_receipt.svg');
        }
    } 

    &.vismaicon-road-toll {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_road_toll.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_road_toll.svg');
        }
    } 

    &.vismaicon-train {
        &:before {
            -webkit-mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_train.svg');
            mask-image: url('#{$vud-icons-path}/worksurface-dynamic/24_train.svg');
        }
    } 

}