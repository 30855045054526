// VUD variables
//
//=== Table of Contents:
//
//  1. Color palette 
//  2. Light mode CSS Variables
//  3. Dark mode CSS Variables

//===  Imports 
//# Functions 
@use "sass:list";
@import '../utilities/functions';


//=== 1. Color palette

//# Maps
//### Color map
$color-map: (
    white: #ffffff,
    black: #000000,
    purple: (
        palette: (
            90: #403380,
            80: #4d3d99,
            70: #6154a5,
            60: #7365b8,
            50: #8a80bd,
            40: #9e96c8,
            30: #b2abd3,
            20: #c6c1df,
            10: #dad7ea,
            05: #edecf5
        ),
        primary: #4d3d99 // $purple-80
    ),
    blue: (
        palette: (
            90: #1b384c,
            80: #14476a,
            70: #0d5788,
            60: #116fae,
            50: #1482cc,
            40: #419bd7,
            30: #6cb2e1,
            20: #99cbec,
            10: #d2eafa,
            05: #e7f2f9
        ),
        primary: #116fae // $blue-60
    ),          
    green: (
        palette: (
            90: #254934,
            80: #316245,
            70: #2d7048,
            60: #4e9c54,
            50: #67a870,
            40: #7faf7f,
            30: #9bc29b,
            20: #afd4af,
            10: #cfe7cf,
            05: #ebf7eb
        ),
        primary: #2d7048 // $green-70
    ),
    red: (
        palette: (
            90: #801f30,
            80: #a12036,
            70: #cc334c,
            60: #d44f64,
            50: #db6b7d,
            40: #e28594,
            30: #eaa1ac,
            20: #f1bdc4,
            10: #fad9de,
            05: #ffedef
        ),
        primary: #cc334c // $red-70
    ),
    orange: (
        palette: (
            90: #803b00,
            80: #b35300,
            70: #c26800,
            60: #CA7900,
            50: #e5a23e,
            40: #edb45f,
            30: #f2c480,
            20: #fad7a2,
            10: #fcdfb1,
            05: #fcf2dc
        ),
        primary: #803b00 // $orange-90
    ),
    neutral: (
        light: (
            palette: (
                90: #252626,
                80: #494a4a,
                70: #6b6b6b,
                60: #8a8a8a,
                50: #a5a5a5,
                40: #c0c0c0,
                30: #dadada,
                20: #f2f2f2,
                10: #f7f7f7,
                05: #ffffff // $white
            ),
            primary: #252626 // $neutral-90
        ),
        dark: (
            palette: (
                90: #000000,
                80: #15181a,
                70: #2b3033,
                60: #4c545b,
                50: #6f7a82,
                40: #86929b,
                30: #9aa5ae,
                20: #afbcc4,
                10: #c3d0d9,
                05: #e4ebf0
            ),
            primary: #2b3033 // $neutral-70
        )
    )
  ) !default;


//# Main colors palette
//#
$white: get-map-value($color-map, white) !default;
$black: get-map-value($color-map, black) !default;


//### Purple palette
$purple-90: get-map-value($color-map, purple, palette, 90) !default;
$purple-80: get-map-value($color-map, purple, palette, 80) !default;
$purple-70: get-map-value($color-map, purple, palette, 70) !default;
$purple-60: get-map-value($color-map, purple, palette, 60) !default;
$purple-50: get-map-value($color-map, purple, palette, 50) !default;
$purple-40: get-map-value($color-map, purple, palette, 40) !default;
$purple-30: get-map-value($color-map, purple, palette, 30) !default;
$purple-20: get-map-value($color-map, purple, palette, 20) !default;
$purple-10: get-map-value($color-map, purple, palette, 10) !default;
$purple-05: get-map-value($color-map, purple, palette, 05) !default;

$primary-purple: get-map-value($color-map, purple, primary) !default;
$purple-primary: get-map-value($color-map, purple, primary) !default;


//### Blue palette
$blue-90: get-map-value($color-map, blue, palette, 90) !default;
$blue-80: get-map-value($color-map, blue, palette, 80) !default;
$blue-70: get-map-value($color-map, blue, palette, 70) !default;
$blue-60: get-map-value($color-map, blue, palette, 60) !default;
$blue-50: get-map-value($color-map, blue, palette, 50) !default;
$blue-40: get-map-value($color-map, blue, palette, 40) !default;
$blue-30: get-map-value($color-map, blue, palette, 30) !default;
$blue-20: get-map-value($color-map, blue, palette, 20) !default;
$blue-10: get-map-value($color-map, blue, palette, 10) !default;
$blue-05: get-map-value($color-map, blue, palette, 05) !default;

$primary-blue: get-map-value($color-map, blue, primary) !default;
$blue-primary: get-map-value($color-map, blue, primary) !default;


//### Green palette
$green-90: get-map-value($color-map, green, palette, 90) !default;
$green-80: get-map-value($color-map, green, palette, 80) !default;
$green-70: get-map-value($color-map, green, palette, 70) !default;
$green-60: get-map-value($color-map, green, palette, 60) !default;
$green-50: get-map-value($color-map, green, palette, 50) !default;
$green-40: get-map-value($color-map, green, palette, 40) !default;
$green-30: get-map-value($color-map, green, palette, 30) !default;
$green-20: get-map-value($color-map, green, palette, 20) !default;
$green-10: get-map-value($color-map, green, palette, 10) !default;
$green-05: get-map-value($color-map, green, palette, 05) !default;

$primary-green: get-map-value($color-map, green, primary) !default;


//### Red palette
$red-90: get-map-value($color-map, red, palette, 90) !default;
$red-80: get-map-value($color-map, red, palette, 80) !default;
$red-70: get-map-value($color-map, red, palette, 70) !default;
$red-60: get-map-value($color-map, red, palette, 60) !default;
$red-50: get-map-value($color-map, red, palette, 50) !default;
$red-40: get-map-value($color-map, red, palette, 40) !default;
$red-30: get-map-value($color-map, red, palette, 30) !default;
$red-20: get-map-value($color-map, red, palette, 20) !default;
$red-10: get-map-value($color-map, red, palette, 10) !default;
$red-05: get-map-value($color-map, red, palette, 05) !default;

$primary-red: get-map-value($color-map, red, primary) !default;


//### Orange palette
$orange-90: get-map-value($color-map, orange, palette, 90) !default;
$orange-80: get-map-value($color-map, orange, palette, 80) !default;
$orange-70: get-map-value($color-map, orange, palette, 70) !default;
$orange-60: get-map-value($color-map, orange, palette, 60) !default;
$orange-50: get-map-value($color-map, orange, palette, 50) !default;
$orange-40: get-map-value($color-map, orange, palette, 40) !default;
$orange-30: get-map-value($color-map, orange, palette, 30) !default;
$orange-20: get-map-value($color-map, orange, palette, 20) !default;
$orange-10: get-map-value($color-map, orange, palette, 10) !default;
$orange-05: get-map-value($color-map, orange, palette, 05) !default;

$primary-orange: get-map-value($color-map, orange, primary) !default;


//# Neutral color palette
//### Light mode palette
$neutral-light-90: get-map-value($color-map, neutral, light, palette, 90) !default;
$neutral-light-80: get-map-value($color-map, neutral, light, palette, 80) !default;
$neutral-light-70: get-map-value($color-map, neutral, light, palette, 70) !default;
$neutral-light-60: get-map-value($color-map, neutral, light, palette, 60) !default;
$neutral-light-50: get-map-value($color-map, neutral, light, palette, 50) !default;
$neutral-light-40: get-map-value($color-map, neutral, light, palette, 40) !default;
$neutral-light-30: get-map-value($color-map, neutral, light, palette, 30) !default;
$neutral-light-20: get-map-value($color-map, neutral, light, palette, 20) !default;
$neutral-light-10: get-map-value($color-map, neutral, light, palette, 10) !default;
$neutral-light-05: get-map-value($color-map, neutral, light, palette, 05) !default;

$primary-neutral-light: get-map-value($color-map, neutral, light, primary) !default;


//### Dark mode palette
$neutral-dark-90: get-map-value($color-map, neutral, dark, palette, 90) !default;
$neutral-dark-80: get-map-value($color-map, neutral, dark, palette, 80) !default;
$neutral-dark-70: get-map-value($color-map, neutral, dark, palette, 70) !default;
$neutral-dark-60: get-map-value($color-map, neutral, dark, palette, 60) !default;
$neutral-dark-50: get-map-value($color-map, neutral, dark, palette, 50) !default;
$neutral-dark-40: get-map-value($color-map, neutral, dark, palette, 40) !default;
$neutral-dark-30: get-map-value($color-map, neutral, dark, palette, 30) !default;
$neutral-dark-20: get-map-value($color-map, neutral, dark, palette, 20) !default;
$neutral-dark-10: get-map-value($color-map, neutral, dark, palette, 10) !default;
$neutral-dark-05: get-map-value($color-map, neutral, dark, palette, 05) !default;

$primary-neutral-dark: get-map-value($color-map, neutral, dark, primary) !default;


//### Neutral palette
$neutral-90: $neutral-light-90 !default;
$neutral-80: $neutral-light-80 !default;
$neutral-70: $neutral-light-70 !default;
$neutral-60: $neutral-light-60 !default;
$neutral-50: $neutral-light-50 !default;
$neutral-40: $neutral-light-40 !default;
$neutral-30: $neutral-light-30 !default;
$neutral-20: $neutral-light-20 !default;
$neutral-10: $neutral-light-10 !default;
$neutral-05: $neutral-light-05 !default;

$primary-neutral: $primary-neutral-light !default;


//### Main font-size
// base/html font size
$vud-units: 'rem' !default;
$html-font-size: 62.5% !default; // --> 1rem = 10px


/* Setup a few global scope properties */
:root {
    //# a. Color palette
    --white: #{$white};
    --black: #{$black};


    //### Purple palette
    --purple-90: #{$purple-90};
    --purple-80: #{$purple-80};
    --purple-70: #{$purple-70};
    --purple-60: #{$purple-60}; 
    --purple-50: #{$purple-50};
    --purple-40: #{$purple-40};
    --purple-30: #{$purple-30};
    --purple-20: #{$purple-20};
    --purple-10: #{$purple-10};
    --purple-05: #{$purple-05};

    --primary-purple: #{$primary-purple};


    //### Blue palette
    --blue-90: #{$blue-90};
    --blue-80: #{$blue-80};
    --blue-70: #{$blue-70};
    --blue-60: #{$blue-60}; 
    --blue-50: #{$blue-50};
    --blue-40: #{$blue-40};
    --blue-30: #{$blue-30};
    --blue-20: #{$blue-20};
    --blue-10: #{$blue-10};
    --blue-05: #{$blue-05};

    --primary-blue: #{$primary-blue};


    //### Green palette
    --green-90: #{$green-90};
    --green-80: #{$green-80};
    --green-70: #{$green-70};
    --green-60: #{$green-60};
    --green-50: #{$green-50};
    --green-40: #{$green-40};
    --green-30: #{$green-30};
    --green-20: #{$green-20};
    --green-10: #{$green-10};
    --green-05: #{$green-05};
    
    --primary-green: #{$primary-green};


    //### Red palette
    --red-90: #{$red-90};
    --red-80: #{$red-80};
    --red-70: #{$red-70};
    --red-60: #{$red-60};
    --red-50: #{$red-50};
    --red-40: #{$red-40};
    --red-30: #{$red-30};
    --red-20: #{$red-20};
    --red-10: #{$red-10};
    --red-05: #{$red-05};
    
    --primary-red: #{$primary-red};


    //### Orange palette
    --orange-90: #{$orange-90};
    --orange-80: #{$orange-80};
    --orange-70: #{$orange-70};
    --orange-60: #{$orange-60};
    --orange-50: #{$orange-50};
    --orange-40: #{$orange-40};
    --orange-30: #{$orange-30};
    --orange-20: #{$orange-20};
    --orange-10: #{$orange-10};
    --orange-05: #{$orange-05};
    
    --primary-orange: #{$primary-orange};
}


// Light and Dark CSS Variables
//
// 2. Light mode CSS Variables
@mixin light-mode {
  //=== a. Color palette
  //# Neutral palette
  --neutral-90: #{$neutral-light-90};
  --neutral-80: #{$neutral-light-80};
  --neutral-70: #{$neutral-light-70};
  --neutral-60: #{$neutral-light-60};
  --neutral-50: #{$neutral-light-50};
  --neutral-40: #{$neutral-light-40};
  --neutral-30: #{$neutral-light-30};
  --neutral-20: #{$neutral-light-20};
  --neutral-10: #{$neutral-light-10};
  --neutral-05: #{$neutral-light-05};

  --primary-neutral: #{$primary-neutral-light};

  // worksourface, action and social media icons
  --icon-bg: var(--custom-icon-bg, var(--blue-50));
  --icon-hover-bg: var(--custom-icon-hover-bg, var(--blue-40));
  --icon-disabled-bg: var(--custom-icon-disabled-bg, var(--neutral-60));

  // Informative icons
  --icon-informative-success-bg: var(--custom-icon-informative-success-bg, var(--green-60));
  --icon-informative-error-bg: var(--custom-icon-informative-error-bg, var(--red-70));
  --icon-informative-warning-bg: var(--custom-icon-informative-warning-bg, var(--orange-70));
  --icon-informative-info-bg: var(--custom-icon-informative-info-bg, var(--blue-50));
  --icon-informative-help-bg: var(--custom-icon-informative-help-bg, var(--blue-50));

  // In buttons
  --button-icon: var(--custom-dark-button-icon, var(--neutral-05));
  --button-primary-icon: var(--custom-button-primary-icon, var(--neutral-05));
}
// END Light mode



//
// 3. Dark mode CSS Variables
@mixin dark-mode {
  //=== a. Color palette
  //# Neutral palette
  --neutral-90: #{$neutral-dark-90};
  --neutral-80: #{$neutral-dark-80};
  --neutral-70: #{$neutral-dark-70};
  --neutral-60: #{$neutral-dark-60};
  --neutral-50: #{$neutral-dark-50};
  --neutral-40: #{$neutral-dark-40}; 
  --neutral-30: #{$neutral-dark-30};
  --neutral-20: #{$neutral-dark-20};
  --neutral-10: #{$neutral-dark-10};
  --neutral-05: #{$neutral-dark-05};

  --primary-neutral: #{$primary-neutral-dark};


  // worksourface, action and social media icons
  --icon-bg: var(--custom-dark-icon-bg, var(--blue-30));
  --icon-hover-bg: var(--custom-dark-icon-hover-bg, var(--blue-40));
  --icon-disabled-bg: var(--custom-dark-icon-disabled-bg, var(--neutral-40));

  // Informative icons
  --icon-informative-success-bg: var(--custom-dark-icon-informative-success-bg, var(--green-60));
  --icon-informative-error-bg: var(--custom-dark-icon-informative-error-bg, var(--red-60));
  --icon-informative-warning-bg: var(--custom-dark-icon-informative-warning-bg, var(--orange-70));
  --icon-informative-info-bg: var(--custom-dark-icon-informative-info-bg, var(--blue-50));
  --icon-informative-help-bg: var(--custom-dark-icon-informative-help-bg, var(--blue-50));

  // in buttons
  --button-icon: var(--custom-dark-button-icon, var(--neutral-05));
  --button-primary-icon: var(--custom-dark-button-primary-icon, var(--neutral-05));
}
